import { Location } from '@element-plus/icons';
import { defineComponent } from 'vue';
import { mapActions } from 'vuex';
import ProjectQuoteLeftSidebarFilters from '@/core/components/project-quote/ProjectQuoteLeftSidebarFilters.vue';
import RatingStars from '@/core/components/ratings/RatingStars.vue';
import UserThumbnail from '@/core/components/ui/UserThumbnail.vue';
import { USERS_STORE } from '@/store/modules/users';
export default defineComponent({
    name: 'project-quote-left-sidebar-list',
    emits: ['select-project-quote', 'filter-project-quotes'],
    components: {
        ProjectQuoteLeftSidebarFilters,
        UserThumbnail,
        Location,
        RatingStars
    },
    props: {
        selectedProjectQuote: {
            type: Object,
            required: true,
            default: () => {
                return null;
            }
        },
        projectQuotes: {
            type: Object,
            required: true,
        }
    },
    data() {
        return {
            mappedProjectQuotes: []
        };
    },
    methods: {
        ...mapActions(USERS_STORE, ['getUserRatingSummary']),
        filterProjectQuotes(event) {
            this.$emit('filter-project-quotes', event);
        },
    },
    async created() {
        const { projectQuotes } = this;
        const promiseList = projectQuotes.map(async (projectQuote) => {
            const ratingSummary = await this.getUserRatingSummary(projectQuote.projectQuoteCreator.id);
            return {
                ...projectQuote,
                ratingSummary
            };
        });
        this.mappedProjectQuotes = await Promise.all(promiseList);
    }
});
