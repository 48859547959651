import { defineComponent } from 'vue';
export default defineComponent({
    name: 'project-quote-left-sidebar-filters',
    emits: ['filter'],
    components: {},
    data: () => {
        return {
            searchMessageList: '',
        };
    },
    methods: {
        onSearch() {
            this.$emit('filter', this.searchMessageList);
        }
    }
});
