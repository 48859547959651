import { Location } from '@element-plus/icons';
import { defineComponent } from 'vue';
import { mapActions, mapGetters } from 'vuex';
import QuoteSummaryCard from '@/core/components/project/project-card-statuses/ProjectQuoteSummaryCard.vue';
import RatingStars from '@/core/components/ratings/RatingStars.vue';
import UserThumbnail from '@/core/components/ui/UserThumbnail.vue';
import { USERS_STORE } from '../../../store/modules/users';
export default defineComponent({
    name: 'project-quote-overview-summary',
    emits: ['confirm-accept-quote', 'confirm-reject-quote'],
    components: {
        QuoteSummaryCard,
        UserThumbnail,
        Location,
        RatingStars
    },
    props: {
        projectQuote: Object,
        project: Object,
    },
    data() {
        return {
            ratingSummary: null
        };
    },
    computed: {
        ...mapGetters(USERS_STORE, ['user']),
    },
    methods: {
        ...mapActions(USERS_STORE, ['getUserRatingSummary']),
    },
    async created() {
        const { projectQuote } = this;
        const { projectQuoteCreator } = projectQuote;
        this.ratingSummary = await this.getUserRatingSummary(projectQuoteCreator.id);
    }
});
