import { defineComponent } from 'vue';
import { mapActions, mapGetters } from 'vuex';
import ProjectQuoteEmpty from '@/core/components/project-quote/placeholder/ProjectQuoteEmpty.vue';
import ProjectQuoteOverviewDetailSkeleton from '@/core/components/project-quote/placeholder/ProjectQuoteOverviewDetailSkeleton.vue';
import ProjectQuoteOverviewSidebarSkeleton from '@/core/components/project-quote/placeholder/ProjectQuoteOverviewSidebarSkeleton.vue';
import ProjectQuoteOverviewSummarySkeleton from '@/core/components/project-quote/placeholder/ProjectQuoteOverviewSummarySkeleton.vue';
import ProjectQuoteContainerSlot from '@/core/components/project-quote/ProjectQuoteContainerSlot.vue';
import ProjectQuoteLeftSidebarList from '@/core/components/project-quote/ProjectQuoteLeftSidebarList.vue';
import { ProjectQuoteStatusEnum } from '@/core/packages/shared-library';
import ProjectQuoteOverviewDetail from '@/modules/project-quote-overview/components/ProjectQuoteOverviewDetail.vue';
import ProjectQuoteOverviewSummary from '@/modules/project-quote-overview/components/ProjectQuoteOverviewSummary.vue';
import ConfirmProceedAcceptProjectQuoteModal from '@/modules/project-quote-overview/modals/ConfirmProceedAcceptProjectQuoteModal.vue';
import ConfirmProceedRejectProjectQuoteModal from '@/modules/project-quote-overview/modals/ConfirmProceedRejectProjectQuoteModal.vue';
import { PROJECT_QUOTES_STORE } from '../../../store/modules/project-quotes';
// eslint-disable-next-line import/no-cycle
import { PROJECTS_STORE } from '../../../store/modules/projects';
import { USERS_STORE } from '../../../store/modules/users';
import { PROJECT_OVERVIEW_ROUTE } from '../../project-overview/routes';
import { PROJECTS_ROUTE } from '../../projects/routes';
export default defineComponent({
    name: 'project-quote-overview-page',
    components: {
        ProjectQuoteContainerSlot,
        ProjectQuoteLeftSidebarList,
        ProjectQuoteOverviewDetailSkeleton,
        ProjectQuoteOverviewSummarySkeleton,
        ProjectQuoteOverviewSidebarSkeleton,
        ProjectQuoteOverviewDetail,
        ProjectQuoteOverviewSummary,
        ProjectQuoteEmpty,
        ConfirmProceedAcceptProjectQuoteModal,
        ConfirmProceedRejectProjectQuoteModal
    },
    data: () => {
        return {
            projectQuoteListIsLoaded: false,
            selectedProject: null,
            projectQuoteList: [],
            selectedProjectQuote: null,
            projectQuoteDetailsLoaded: false,
            openConfirmProceedAcceptProjectQuoteModal: false,
            openConfirmProceedRejectProjectQuoteModal: false,
            loadingConfirmAcceptQuote: false,
            loadingConfirmRejectQuote: false,
            filterProjectQuoteString: '',
        };
    },
    created() {
        this.initialize();
    },
    computed: {
        ...mapGetters(USERS_STORE, ['user']),
        getFilteredProjectQuotes() {
            let result = this.projectQuoteList;
            if (this.filterProjectQuoteString) {
                result = result.filter((projectQuote) => {
                    return projectQuote.notes.toLowerCase().includes(this.filterProjectQuoteString.toLowerCase());
                });
            }
            return result;
        }
    },
    methods: {
        ...mapActions(PROJECTS_STORE, ['getProjectByRefId', 'setSelectedProjectJobStatus']),
        ...mapActions(PROJECT_QUOTES_STORE, ['getProjectQuotesByProject', 'getProjectQuoteDetails', 'getCustomerPay', 'updateProjectQuoteStatus']),
        async initialize() {
            const { projectRefId } = this.$route.params;
            await this.getProjectByRefId(projectRefId)
                .then((response) => {
                if (response) {
                    this.selectedProject = response;
                    this.setSelectedProjectJobStatus(this.selectedProject?.projectJobStatus);
                }
            })
                .catch((error) => {
                this.$notify.error({
                    message: error && error?.message ? error?.message : 'Project not found.'
                });
                this.$router.push({ name: PROJECTS_ROUTE });
            });
            const getProjectQuoteListRequest = await this.getProjectQuotesByProject(this.selectedProject?.id);
            if (getProjectQuoteListRequest?.data?.length) {
                this.projectQuoteList = getProjectQuoteListRequest?.data;
                const [defaultProjectQuote] = this.projectQuoteList;
                const projectQuoteGetTotalPricePromises = [];
                if (this.projectQuoteList?.length) {
                    this.projectQuoteList.forEach((projectQuote) => {
                        projectQuoteGetTotalPricePromises.push(this.getProjectQuoteDetailsWithTotalQuotePrice(projectQuote));
                    });
                    const projectQuoteGetTotalPriceResponses = await Promise.all(projectQuoteGetTotalPricePromises);
                    if (projectQuoteGetTotalPriceResponses?.length) {
                        projectQuoteGetTotalPriceResponses.forEach((response, index) => {
                            if (this.projectQuoteList[index]) {
                                this.projectQuoteList[index].quotePrice = response.total;
                            }
                        });
                    }
                }
                this.selectProjectQuote(defaultProjectQuote.id);
                this.projectQuoteListIsLoaded = true;
            }
            else {
                this.projectQuoteDetailsLoaded = true;
                this.projectQuoteListIsLoaded = true;
            }
        },
        async selectProjectQuote(projectQuoteId) {
            const projectId = this.selectedProject?.id;
            this.projectQuoteDetailsLoaded = false;
            const getProjectQuoteDetails = await this.getProjectQuoteDetails({ projectQuoteId, projectId });
            if (getProjectQuoteDetails) {
                this.selectedProjectQuote = getProjectQuoteDetails;
                /* (this as any).selectedProjectQuote.quotePrice = await (this as any).getProjectQuotePrice((this as any).selectedProjectQuote); */
            }
            else {
                this.$notify.error({
                    title: 'Project Quote Details Error.',
                    message: 'Error fetching project quote details at the moment. Please try to refresh the page.',
                });
            }
            this.projectQuoteDetailsLoaded = true;
        },
        filterProjectQuotes(filterString) {
            this.filterProjectQuoteString = filterString;
        },
        async getProjectQuoteDetailsWithTotalQuotePrice(projectQuote) {
            let result = {
                total: 0,
                serviceFee: 0,
            };
            const projectId = this.selectedProject?.id;
            const projectQuoteDetails = await this.getProjectQuoteDetails({ projectQuoteId: projectQuote?.id, projectId });
            result = await this.getCustomerPay({ projectId: this.selectedProject?.id, quote: projectQuoteDetails });
            return result;
        },
        async submitAcceptProjectQuote() {
            this.loadingConfirmAcceptQuote = true;
            const { projectRefId } = this.$route.params;
            const projectQuoteId = this.selectedProjectQuote?.id;
            const formData = {
                projectQuoteStatusId: ProjectQuoteStatusEnum.QUOTE_ACCEPTED,
            };
            this.updateProjectQuoteStatus({ projectId: this.selectedProject?.id, projectQuoteId, formData })
                .then((response) => {
                if (response) {
                    this.$notify.success({
                        title: 'Project Quote Accepted.',
                        message: 'You successfully accepted the project quote.',
                    });
                    this.$router.push({ name: PROJECT_OVERVIEW_ROUTE, params: { projectRefId } });
                }
            })
                .catch(() => {
                this.$notify.error({
                    title: 'Project Quote Transaction Error.',
                    message: 'The project has been updated recently.',
                });
                setTimeout(() => {
                    window.location.reload();
                }, 1200);
            })
                .finally(() => {
                this.loadingConfirmAcceptQuote = false;
                this.openConfirmProceedAcceptProjectQuoteModal = false;
            });
        },
        cancelAcceptProjectQuote() {
            this.openConfirmProceedAcceptProjectQuoteModal = false;
            this.loadingConfirmAcceptQuote = false;
        },
        async submitRejectProjectQuote() {
            this.loadingConfirmAcceptQuote = true;
            const { projectRefId } = this.$route.params;
            const projectQuoteId = this.selectedProjectQuote?.id;
            const formData = {
                projectQuoteStatusId: ProjectQuoteStatusEnum.QUOTE_REJECTED,
            };
            this.updateProjectQuoteStatus({ projectId: this.selectedProject?.id, projectQuoteId, formData })
                .then((response) => {
                if (response) {
                    this.$notify.success({
                        title: 'Project Quote Rejected.',
                        message: 'You successfully rejected the project quote.',
                    });
                    // (this as any).selectedProjectQuote.projectQuoteStatus = { ...(this as any).selectedProjectQuote.projectQuoteStatus, projectQuoteStatus: response?.projectQuoteStatus };
                    this.$router.push({ name: PROJECT_OVERVIEW_ROUTE, params: { projectRefId } });
                }
            })
                .catch(() => {
                this.$notify.error({
                    title: 'Project Quote Transaction Error.',
                    message: 'The project has been updated recently.',
                });
                setTimeout(() => {
                    window.location.reload();
                }, 1200);
            })
                .finally(() => {
                this.loadingConfirmAcceptQuote = false;
                this.openConfirmProceedRejectProjectQuoteModal = false;
            });
        },
        cancelRejectProjectQuote() {
            this.openConfirmProceedRejectProjectQuoteModal = false;
            this.loadingConfirmRejectQuote = false;
        },
        closeSelectedProjectQuote() {
            this.selectedProjectQuote = null;
        }
    }
});
