import { defineComponent } from 'vue';
export default defineComponent({
    name: 'rating-stars',
    props: {
        average: {
            type: Number,
            required: true,
        },
        total: {
            type: Number,
            required: true,
        }
    },
    computed: {
        avgRating() {
            return (this.average && this.average.toFixed(1)) || 0;
        },
    },
    data() {
        return {
            colors: ['#264FD5', '#264FD5', '#264FD5'],
            rating: 3,
            currentAverageRating: 0,
        };
    },
    created() {
        this.currentAverageRating = this.average;
    }
});
